/* eslint-disable max-len */
import React, {
  useMemo,
  memo,
  useState,
  useEffect,
  useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { FormControl } from '@material-ui/core';

import useFormState from '../../../hooks/useFormState';
import { noopPromise } from '../../../utils';
import InputText from '../../UI/InputText';
import ErrorMessage from '../../UI/ErrorMessage';
import Heading from '../../UI/Heading';
import DragNDrop from '../../DragNDrop/DrugNDrop';
import {
  createTargets,
  updateEventName,
  updateSessionName
} from '../../../redux/target/actions';
import { selectEventsList, selectNewEvent } from '../../../redux/events/selectors';
import { fetchEventsCount, fetchEventsList } from '../../../redux/events/actions';
import Button from '../../UI/Button';
import Loader from '../../UI/Loader/Loader';
import Popup from '../../Popup/Popup';
import FormCreateEvent from '../FormCreateEvent';
import CustomSelect from '../CustomSelect/CustomSelect';
import sortMedia from '../../../utils/sortMedia';
import Alert from '../../UI/Alert';
import FormTargetMediaAdd from '../FormTargetMediaAdd/FormTargetMediaAdd';
import { getIsFetchingSelector } from '../../../redux/target/selectors';
import { REMOVE_FILE_FROM_UPLOAD, RESET_EDIT_POPUP_STATE } from '../../../redux/target/constants';

import style from './FormCreateTarget.scss';

const RULES = {
  title: {
    custom: (value) => {
      if (value.length > 150) {
        return 'Title must be less than 150 characters length';
      }
      return '';
    }
  }
};

const FormCreateTarget = ({
  onSubmit, onFail, onMount, onSuccess, defaultEventId
}) => {
  const dispatch = useDispatch();
  const events = useSelector(selectEventsList);
  const isFetching = useSelector(getIsFetchingSelector);
  const newEvent = useSelector(selectNewEvent);
  const history = useHistory();
  const eventId = history.location.pathname.split('/').pop();

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [targets, setTargets] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(defaultEventId);
  const [noEventSelected, setNoEventSelected] = useState(true);
  const [sessionName, setSessionName] = useState('');
  const [isPopupEventOpen, setIsPopupEventOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    open: false,
    type: 'success',
    message: ''
  });

  useEffect(() => {
    if (onMount) {
      onMount();
      dispatch(fetchEventsList('', 10000, 0));
      if (defaultEventId !== '' || defaultEventId !== 'create') {
        setNoEventSelected(false);
      }
    }
    return () => dispatch({ type: RESET_EDIT_POPUP_STATE });
  }, [onMount, dispatch, defaultEventId]);

  const handleDeleteItem = (url, index) => {
    const fileName = url.split('_').pop();
    dispatch({ type: REMOVE_FILE_FROM_UPLOAD, payload: fileName });

    let shouldRemoveTarget = false;

    if (url.includes('photos')) {
      setImages((prev) => prev.map((image, i) => (i === index ? { ...image, photoUrl: null, photoVector: null } : image)));

      setTargets((prevTargets) => {
        const updatedTargets = prevTargets.map((target, i) => {
          if (i === index) {
            return { ...target, photoUrl: null, photoVector: null };
          }
          return target;
        });

        if (updatedTargets[index].videoUrl === null) {
          shouldRemoveTarget = true;
        }
        return updatedTargets;
      });
    } else if (url.includes('videos')) {
      setVideos((prev) => prev.map((video, i) => {
        if (i === index) {
          return { ...video, videoUrl: null };
        }
        return video;
      }));
      setTargets((prevTargets) => {
        const updatedTargets = prevTargets.map((target, i) => {
          if (i === index) {
            return { ...target, videoUrl: null };
          }
          return target;
        });

        if (updatedTargets[index].photoUrl === null) {
          shouldRemoveTarget = true;
        }
        return updatedTargets;
      });
    }

    if (shouldRemoveTarget) {
      setTargets((prevTargets) => prevTargets.filter((_, i) => i !== index));
      setImages((prev) => prev.filter((_, i) => i !== index));
      setVideos((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const {
    processing,
    errors
  } = useFormState(RULES, onSubmit, onFail);

  const openPopupCreateEvent = useCallback(() => {
    setIsPopupEventOpen(true);
  }, []);

  const closePopupEvent = useCallback(() => {
    setIsPopupEventOpen(false);
  }, []);

  useEffect(() => {
    if (isPopupEventOpen) {
      const fetchData = async() => {
        await dispatch(fetchEventsList('', 10000, 0));
        await dispatch(fetchEventsCount());
        closePopupEvent();
      };

      fetchData();
    }
  }, [ newEvent, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSessionNameChange = (event) => {
    setSessionName(event);
    dispatch(updateSessionName(event));
  };

  const handleEventSelect = (eventValue) => {
    if (eventValue === 'create') {
      openPopupCreateEvent();
    } else if (eventValue === '') {
      setNoEventSelected(true);
    } else {
      setSelectedEvent(eventValue);
      setNoEventSelected(false);
      dispatch(updateEventName(eventValue));
    }
  };

  const handleCloseAlert = () => {
    setAlertMsg({ ...alertMsg, open: false });
  };

  const showAlert = (message, type = 'error') => {
    setAlertMsg({ open: true, type, message });
  };

  const onFileUpload = (action) => {
    const isImage = action.url.includes('photos');
    const isVideo = action.url.includes('videos');
    const fileName = action?.file.name;

    let newTarget = {};

    const { url } = action;
    if (isImage) {
      const isImageExists = images.some((img) => img.photoUrl?.includes(fileName));
      if (isImageExists) {
        showAlert(`Photo ${fileName} already exists in the target`);
        return;
      }
      newTarget = {
        photoUrl: url
      };
      setImages((prevImages) => {
        const updatedImages = [...prevImages];
        const nullImageIndex = updatedImages.findIndex((img) => img.photoUrl === null);
        if (nullImageIndex !== -1) {
          updatedImages[nullImageIndex] = { photoUrl: url };
        } else {
          updatedImages.push({ photoUrl: url });
        }
        return sortMedia(updatedImages);
      });
    } else if (isVideo) {
      const isVideoExists = videos.some((vid) => vid.videoUrl?.includes(fileName));
      if (isVideoExists) {
        showAlert(`Video ${fileName} already exists in the target`);
        return;
      }
      newTarget = { videoUrl: url };
      setVideos((prevVideo) => {
        const updatedVideos = [...prevVideo];
        const nullImageIndex = updatedVideos.findIndex((vid) => vid.videoUrl === null);
        if (nullImageIndex !== -1) {
          updatedVideos[nullImageIndex] = { videoUrl: url };
        } else {
          updatedVideos.push({ videoUrl: url });
        }
        return sortMedia(updatedVideos);
      });
    }

    const updatedTargets = [...targets];
    if (newTarget.photoUrl || newTarget.videoUrl) {
      const targetIndex = updatedTargets.findIndex((target) => (
        (newTarget.photoUrl && !target.photoUrl)
        || (newTarget.videoUrl && !target.videoUrl)
      ));
      if (targetIndex >= 0) {
        updatedTargets[targetIndex] = {
          ...updatedTargets[targetIndex],
          ...newTarget
        };
      } else {
        updatedTargets.push({
          photoUrl: newTarget.photoUrl || null,
          photoVector: newTarget.photoVector || null,
          videoUrl: newTarget.videoUrl || null,
          title: '',
          sound: true
        });
      }
    }
    const filteredTargets = updatedTargets.filter((target) => target.photoUrl || target.videoUrl);
    setTargets((prevState) => [...prevState, filteredTargets]);
  };

  const handleFormSubmit = async(event) => {
    event.preventDefault();
    const updatedTargets = targets?.map((item) => ({
      ...item,
      photoUrl: item.photoUrl,
      photoVector: item.photoVector,
      videoUrl: item.videoUrl
    }));
    const data = new FormData();
    if (targets.length > 1) {
      data.append('sessionName', sessionName);
    }
    if (selectedEvent !== '' && selectedEvent !== null) {
      data.append('eventId', selectedEvent);
    }
    data.append('targets', JSON.stringify(updatedTargets));
    try {
      await dispatch(createTargets(data));
      if (onSuccess) {
        onSuccess();
        if ((Number(selectedEvent) === Number(eventId) && selectedEvent !== '')
          || (history.location.pathname.includes('/events/') && noEventSelected)
          || (selectedEvent && (history.location.pathname === '/targets/'))
          || (selectedEvent !== '')) {
          history.push(`/events/${selectedEvent}`);
        } else if (!selectedEvent && (history.location.pathname !== '/targets/')) {
          history.push('/targets/');
        } else {
          history.push('/');
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error('Error creating targets:', error);
    }
  };

  useEffect(() => {
    const emptyImageIndexes = images.reduce((acc, item, index) => {
      if (!item.photoUrl) {
        acc.push(index);
      }
      return acc;
    }, []);

    const emptyVideoIndexes = videos.reduce((acc, item, index) => {
      if (!item.videoUrl) {
        acc.push(index);
      }
      return acc;
    }, []);
    const [commonEmptyIndex] = emptyImageIndexes.filter((index) => emptyVideoIndexes.includes(index));
    if (commonEmptyIndex !== undefined) {
      setImages((prev) => prev.filter((_, i) => i !== commonEmptyIndex));
      setVideos((prev) => prev.filter((_, i) => i !== commonEmptyIndex));
    }
  }, [targets.length]); // eslint-disable-line react-hooks/exhaustive-deps

  const isButtonDisabled = useMemo(() => {
    const pairs = targets?.filter((target) => (target.photoUrl && !target.videoUrl) || (target.videoUrl && !target.photoUrl));
    const disabled = pairs?.length !== 0 || targets.length === 0 || isFetching;
    return disabled;
  }, [targets, isFetching]);
  const showSessionNameField = images?.length >= 2 || videos?.length >= 2;
  return (
    <>
      <form onSubmit={handleFormSubmit} noValidate className={style.form}>
        <Heading align="center" level={2} weight={500} fSize={24} mBottom={14}>
          Create Target
        </Heading>
        <DragNDrop
          targets={targets}
          images={images}
          videos={videos}
          handleDeleteItem={handleDeleteItem}
          setSingleImage={setImages}
          setSingleVideo={setVideos}
          setPairedData={setTargets}
          isCreateForm
        />
        <div className={style.dragHolder}>
          <FormTargetMediaAdd
            text="Upload Pictures and Videos"
            acceptedFiles={['image/*', 'video/*']}
            onSuccess={onFileUpload}
          />
        </div>
        <div className={style.inputHolder}>
          {showSessionNameField ? (
            <FormControl margin="none" fullWidth>
              <InputText
                type="text"
                id="sessionName"
                name="sessionName"
                placeholder="Session name"
                disabled={processing}
                errors={errors.sessionName}
                value={sessionName}
                onChange={handleSessionNameChange}
                fullWidth
              />
            </FormControl>
          ) : null}
          <CustomSelect
            placeholder="Select Event..."
            label="Choose Event"
            options={events}
            selectedValue={selectedEvent}
            onSelect={handleEventSelect}
            onCreateEvent={openPopupCreateEvent}
          />
        </div>

        {errors.detail && (
          <div className={style.nonFieldError}>
            <ErrorMessage>
              {errors.detail}
            </ErrorMessage>
          </div>
        )}

        <div className={style.submitButton}>
          <Button
            type="submit"
            disabled={isButtonDisabled}
            fullWidth
          >
            Create
          </Button>
        </div>
        {processing && <Loader />}
      </form>
      <Alert
        open={alertMsg.open}
        handleCloseAlert={handleCloseAlert}
        type={alertMsg.type}
      >
        {alertMsg.message}
      </Alert>
      <Popup open={isPopupEventOpen} handleClose={closePopupEvent}>
        <FormCreateEvent />
      </Popup>
    </>
  );
};

FormCreateTarget.propTypes = {
  onSubmit: PropTypes.func,
  onFail: PropTypes.func,
  onMount: PropTypes.func,
  onSuccess: PropTypes.func,
  defaultEventId: PropTypes.string
};

FormCreateTarget.defaultProps = {
  onSubmit: noopPromise,
  onFail: noopPromise.apply,
  onMount: noopPromise,
  onSuccess: noopPromise,
  defaultEventId: ''
};

export default memo(FormCreateTarget);
